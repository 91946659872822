import Layout from 'components/layout';
import { graphql, Link, navigate } from 'gatsby';
import React from 'react';
import SEO from 'components/seo';
import Banner from 'components/banner';
import Button from 'components/button';
import locationIcon from 'images/location-arrow.svg';
import clockIcon from 'images/clock.svg';
import dollarIcon from 'images/dollar-sign.svg';
import careerIcon from 'images/file-media.png';

const bannerInfo = {
  height: '400px',
  title: 'OPEN ROLES',
  cotent:
    'We are always looking for talents to work with us, even with the roles we’ve not been hiring yet. If you find your ability is matching with SkylineTech’s values, don’t hesitate to give us a chance.',
};

const CareerPage = ({ data }: any) => {
  const jobsList = data.allMdx.edges;
  const onHandleClick = (data: any) => {
    navigate(`${data}`, { state: { name: 'career' } });
  };
  return (
    <Layout>
      <SEO title="Career" />
      <Banner height={bannerInfo.height} title={bannerInfo.title} content={bannerInfo.cotent} />
      <div className="container xl:px-[50px] mx-auto bg-white py-[80px]">
        <div className="max-w-[1320px] grid grid-cols-1 xl:grid-cols-2 gap-[24px] mx-auto">
          {jobsList.map((job: any, index: number) => (
            <div
              key={index}
              className="flex items-center justify-between min-h-[149px] w-full max-w-[648px] mx-auto px-4 bg-[#ffffff] shadow-[0px_7px_20px_rgba(0,0,0,0.15)]">
              <img className="w-11 hidden sm:block" src={careerIcon} alt="icon"></img>
              <div>
                <Link to={job.node.slug} state={{ name: 'career' }}>
                  <span className="block text-xl font-semibold text-[#2A2F36] break-words mb-[15px] hover:text-[#2EBBEC]">{job.node.frontmatter.title}</span>
                </Link>
                <div className="block sm:flex justify-around">
                  <div className="text-[#939393] bg-[#F9F9F9] rounded-[14px] px-[10px] flex items-center h-[28px] max-w-[200px] mb-1">
                    <img className="w-3 h-3" src={locationIcon} alt="icon"></img>
                    <span className="text-xs font-sm text-[#939393] px-[10px]">{job.node.frontmatter.site}</span>
                  </div>
                  <div className="text-[#939393] bg-[#F9F9F9] rounded-[14px] px-[10px] flex items-center h-[28px] max-w-[200px] mb-1">
                    <img className="w-3 h-3" src={clockIcon} alt="icon"></img>
                    <span className="text-xs font-sm text-[#939393] px-[10px]">{job.node.frontmatter.time}</span>
                  </div>
                  <div className="text-[#939393] bg-[#F9F9F9] rounded-[14px] px-[10px] flex items-center h-[28px] max-w-[200px] mb-1">
                    <img className="w-3 h-3" src={dollarIcon} alt="icon"></img>
                    <span className="text-xs font-sm text-[#939393] px-[10px]">{job.node.frontmatter.salary}</span>
                  </div>
                </div>
              </div>
              <div className=" h-[32px] min-w-[103px] rounded-[40px] text-sm font-medium" />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const data = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: ASC }) {
      edges {
        node {
          frontmatter {
            career
            salary
            site
            time
            title
          }
          slug
        }
      }
    }
  }
`;

export default CareerPage;
