import React from 'react';
import MenuBar from './menu-bar';
import 'styles/banner.css';
interface BannerProps {
  height: string;
  title?: string;
  content?: string;
}

const Banner = ({ height, title = '', content = '' }: BannerProps) => {
  return (
    <div className="banner-bg__img flex flex-col object-fill bg-no-repeat bg-cover py-[33px] mt-[80px]" style={{ height: `${height}` }}>
      <div className="container mx-auto"></div>
      <div className=" container flex flex-col justify-center max-w-[625px] mx-auto text-center flex-1">
        <h1 className="text-[42px] font-bold text-[#2A2F36] mb-2">{title}</h1>
        <p className="text-sm italic text-[#929292] text-center">{content}</p>
      </div>
    </div>
  );
};

export default Banner;
